
:root{
  --footer-bg: #EDF0ED;
  --footer-bg-2: #E1E8E1;
  --gray-dark: #666666;
  --orange: #E6581B;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.btn-primary{
  background-color: #016590 !important;
  border:1px solid #016590 !important;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active{
  background-color: #003951 !important;
  border:1px solid #003951 !important;
}
.btn-danger{
  background-color: #f47828 !important;
  border:1px solid #f47828 !important;
}
.btn-danger:hover, .btn-danger:focus, .btn-danger:active{
  background-color: #f47828 !important;
  border:1px solid #f47828 !important;
}
.bg-light-primary{
  background-color: #d0dce1 !important;
}
[aria-labelledby="user-settings"]{
  right: 0;
}
.btn-outline-primary{
 color: #003951 !important;
  border:1px solid #003951 !important;
}
.btn-outline-primary:hover{
  color: #fff !important;
   border:1px solid #003951 !important;
   background:#003951 !important;
 }
 .bg-secondary{
  background:#003951 !important;
 }
 .subNavLink:hover{
  color: white !important;
 }
.subNavLink.xrg-nav-selected{
  color: #f47828 !important;
 }
 .subNavLink.xrg-nav-selected{
  border-color: #f47828;
 }
 .css-b8ldur-Input{
  margin: 0 !important;
  padding: 0 !important;
 }
 .form-label{
  font-size: .875rem !important;
 }
 .custom-file-input::before{
  border:1px solid #003951 !important;
  color: #003951;
 }
 .custom-file-input::before:hover{
  color: #fff !important;
  background-color: #003951 !important;
 }
 .text-primary{
  color: #003951 !important;
 }
@media (max-width:767px) {
  .reg-no{
    position: absolute;
    display: block;
    max-width: 767px;
    margin: 0 auto;  
    width: 100%;
    left: 0;
    text-align: center;
    top: 50px;
  }
  #user-settings{
    max-width: 98px;
      overflow: hidden;
      text-overflow: ellipsis;
  }
  .navbar {
    padding-top: .5rem !important;
    padding-bottom:1.35rem !important;
  }
}

/* input type number hide arrows */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.footer-bottom {
  background-color: #ffffff;
  padding: 0.2rem 0;
  font-size: .875rem;
  color: var(--gray-dark);

}

.footer-bottom a {
  color: var(--gray-dark);
  text-decoration: none;
  padding: .5rem;
  transition: all .3s;
}

.footer-bottom a:hover {
  color: var(--orange);
}